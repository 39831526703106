import React from "react"
import InvestorRelationsLayout from '../../../components/InvestorRelationsLayout'
import { graphql } from 'gatsby'
import LocaleContext from '../../../contexts/LocaleContext'
import Layout from '../../../components/Layout'
import Menu from '../../../components/Menu'
import Footer from '../../../components/Footer'

export const query = graphql`
  query ($locale: String!){
    datoCmsInvestorRelationsPage ( locale: { eq: $locale }){
      governance
    }
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function IPO({ data, pageContext }) {
  const { governance } = data.datoCmsInvestorRelationsPage
  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
        <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <div className="margin-top" dangerouslySetInnerHTML={{
              __html: governance,
            }}></div>
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
